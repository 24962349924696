{
  "name": "envestboard-ui",
  "version": "1.53.0",
  "scripts": {
    "ng": "ng",
    "start": "npm run i18n:cache-busting && ng serve",
    "start-target": "npm run i18n:cache-busting && ng serve --configuration $BUILD_ENV",
    "build": "npm run i18n:cache-busting && ng build --configuration $BUILD_ENV",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "test:watch": "ng test --watch",
    "e2e": "ng e2e",
    "i18n:cache-busting": "node ./i18n-cache-busting.js",
    "compodoc:build:json": "compodoc -p ./tsconfig.json -e json -d .",
    "storybook:serve": "ng run envestboard-ui:storybook",
    "storybook:build": "npm run i18n:cache-busting && npm run compodoc:build:json && ng run envestboard-ui:build-storybook",
    "cypress:open": "cypress open",
    "cypress:run": "cypress run",
    "lint": "npm run i18n:cache-busting && ng lint",
    "lint:styles": "stylelint 'src/**/*.scss'",
    "prepare": "husky",
    "i18n:extract": "transloco-keys-manager extract",
    "i18n:find": "transloco-keys-manager find",
    "build-bundle-report": "ng build --configuration production --source-map && npm source-map-explorer dist/**/*.js --html source-map-explorer.html",
    "compodoc:build": "compodoc -p tsconfig.doc.json",
    "compodoc:build-and-serve": "compodoc -p tsconfig.doc.json -s",
    "compodoc:serve": "compodoc -s"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.0",
    "@angular/cdk": "^18.2.0",
    "@angular/common": "^18.2.0",
    "@angular/compiler": "^18.2.0",
    "@angular/core": "^18.2.0",
    "@angular/forms": "^18.2.0",
    "@angular/material": "^18.2.0",
    "@angular/material-date-fns-adapter": "^18.2.0",
    "@angular/platform-browser": "^18.2.0",
    "@angular/platform-browser-dynamic": "^18.2.0",
    "@angular/router": "^18.2.0",
    "@cypress/schematic": "^2.5.1",
    "@juggle/resize-observer": "^3.4.0",
    "@ngneat/input-mask": "^6.1.0",
    "@ngneat/transloco": "^6.0.4",
    "@ngneat/transloco-locale": "^5.1.2",
    "@ngneat/transloco-messageformat": "^5.0.0",
    "@ngneat/until-destroy": "^10.0.0",
    "@ngxs/devtools-plugin": "^18.1.1",
    "@ngxs/logger-plugin": "^18.1.0",
    "@ngxs/router-plugin": "^18.1.0",
    "@ngxs/storage-plugin": "^18.1.0",
    "@ngxs/store": "^18.1.0",
    "@sentry/angular-ivy": "^7.119.0",
    "@sentry/tracing": "^7.119.0",
    "@types/file-saver": "^2.0.7",
    "@videogular/ngx-videogular": "^8.0.0",
    "angular-eslint": "^18.2.0",
    "angular-google-tag-manager": "^1.9.0",
    "angular-svg-icon": "^18.0.1",
    "date-fns": "^3.6.0",
    "echarts": "^5.5.1",
    "exceljs": "^4.4.0",
    "file-saver": "^2.0.5",
    "flag-icons": "^7.2.3",
    "flat-cjs-for-jest": "npm:flat@^5.0.2",
    "inputmask": "^5.0.9",
    "jwt-decode": "^4.0.0",
    "libphonenumber-js": "^1.11.5",
    "moize": "^6.1.6",
    "ngx-drag-scroll": "^18.0.0",
    "ngx-dropzone": "^3.1.0",
    "ngx-echarts": "^18.0.0",
    "ngx-image-cropper": "^8.0.0",
    "ngx-infinite-scroll": "^18.0.0",
    "ngx-skeleton-loader": "^9.0.0",
    "normalize.css": "^8.0.1",
    "ramda": "^0.30.1",
    "rxjs": "~7.8.1",
    "scrollbooster": "^3.0.2",
    "tslib": "^2.6.3",
    "uid": "^2.0.2",
    "zone.js": "~0.14.8"
  },
  "devDependencies": {
    "@angular-builders/jest": "^18.0.0",
    "@angular-eslint/builder": "^18.2.0",
    "@angular-eslint/eslint-plugin": "^18.2.0",
    "@angular-eslint/eslint-plugin-template": "^18.2.0",
    "@angular-eslint/schematics": "^18.2.0",
    "@angular-eslint/template-parser": "^18.2.0",
    "@angular/build": "^18.1.3",
    "@angular/cli": "^18.1.3",
    "@angular/compiler-cli": "^18.1.3",
    "@angular/elements": "^18.1.3",
    "@commitlint/cli": "^19.3.0",
    "@commitlint/config-conventional": "^19.2.2",
    "@compodoc/compodoc": "^1.1.25",
    "@eslint/compat": "^1.1.1",
    "@eslint/eslintrc": "^3.1.0",
    "@eslint/js": "^9.8.0",
    "@faker-js/faker": "^8.4.1",
    "@ngneat/transloco-keys-manager": "^3.8.0",
    "@semantic-release/changelog": "^6.0.3",
    "@semantic-release/exec": "^6.0.3",
    "@semantic-release/git": "^10.0.1",
    "@semantic-release/npm": "^12.0.1",
    "@storybook/addon-actions": "^8.2.7",
    "@storybook/addon-docs": "^8.2.7",
    "@storybook/addon-essentials": "^8.2.7",
    "@storybook/addon-viewport": "^8.2.7",
    "@storybook/angular": "^8.2.7",
    "@types/core-js": "^2.5.8",
    "@types/inputmask": "^5.0.7",
    "@types/jest": "^29.5.12",
    "@types/node": "^22.1.0",
    "@types/ramda": "^0.30.1",
    "@types/scrollbooster": "^3.0.3",
    "@typescript-eslint/eslint-plugin": "7.11.0",
    "@typescript-eslint/parser": "7.11.0",
    "@webcomponents/custom-elements": "^1.6.0",
    "babel-loader": "^9.1.3",
    "canvas": "^2.11.2",
    "cypress": "^13.13.2",
    "eslint": "^9.8.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-import-resolver-typescript": "^3.6.1",
    "eslint-plugin-change-detection-strategy": "^0.1.4",
    "eslint-plugin-import": "2.29.1",
    "eslint-plugin-jsonc": "^2.13.0",
    "eslint-plugin-modules-newlines": "^0.0.7",
    "eslint-plugin-prettier": "^5.1.3",
    "eslint-plugin-rxjs-angular": "^2.0.1",
    "globals": "^15.9.0",
    "husky": "^9.1.4",
    "jest": "^29.7.0",
    "jest-slow-test-reporter": "^1.0.0",
    "lint-staged": "^15.2.8",
    "orval": "^7.0.0",
    "prettier": "^3.3.3",
    "prettier-plugin-organize-attributes": "^1.0.0",
    "puppeteer": "^23.0.0",
    "react": "^18.3.1",
    "react-dom": "^18.3.1",
    "semantic-release": "^24.0.0",
    "source-map-explorer": "^2.5.3",
    "storybook": "^8.2.7",
    "stylelint": "^16.8.1",
    "stylelint-config-idiomatic-order": "^10.0.0",
    "stylelint-config-standard-scss": "^13.1.0",
    "stylelint-order": "^6.0.4",
    "stylelint-selector-bem-pattern": "^4.0.0",
    "typescript": "^5.5.4",
    "typescript-eslint": "^8.0.1"
  },
  "resolutions": {
    "stylelint-order": "6.0.4"
  }
}

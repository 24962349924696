import { inject } from '@angular/core';
import { uid } from 'uid';

import { ConfigurationFacade } from '~/app/core/state/configuration/configuration.facade';

import { OAuthProvider } from './oauth-provider';

export class NatixisProvider implements OAuthProvider {
    public providerName: string = 'natixis';

    public configurationFacade = inject(ConfigurationFacade);


    getLogoutUrl(isUserInternal: boolean): string {
        const authenticationConfiguration = this.configurationFacade.getAuthenticationSnapshot();
        if (authenticationConfiguration) {
            if (isUserInternal) {
                return `${authenticationConfiguration.oauthConfiguration?.authorityUrl}/v2/logout?client_id=${authenticationConfiguration.oauthConfiguration?.clientId}&returnTo=${authenticationConfiguration.oauthConfiguration?.internalLogoutUrl}`;
            }
            return `${authenticationConfiguration.oauthConfiguration?.authorityUrl}/v2/logout?client_id=${authenticationConfiguration.oauthConfiguration?.clientId}&returnTo=${authenticationConfiguration.oauthConfiguration?.externalLogoutUrl}`;
        }
        return '/auth/login';
    }

    getLoginUrl(): string {
        const authenticationConfiguration = this.configurationFacade.getAuthenticationSnapshot();
        if (authenticationConfiguration
            && authenticationConfiguration.oauthConfiguration?.clientId
            && authenticationConfiguration.oauthConfiguration?.redirectUrl
        ) {
            const url = new URL(`${authenticationConfiguration.oauthConfiguration?.authorityUrl}/authorize`);
            url.searchParams.append('client_id', authenticationConfiguration.oauthConfiguration?.clientId);
            url.searchParams.append('scope', 'openid profile email');
            url.searchParams.append('response_type', 'code');
            url.searchParams.append('redirect_uri', authenticationConfiguration.oauthConfiguration?.redirectUrl);
            url.searchParams.append('state', uid());
            return url.toString();
        }
        // eslint-disable-next-line no-console
        console.warn('provider login url configuration incomplete');
        return '/auth/login';
    }
}

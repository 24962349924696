import { DOCUMENT } from '@angular/common';
import { inject } from '@angular/core';
import {
    CanActivateFn,
} from '@angular/router';

import { OAuthProvider } from '~/app/core/oauth/oauth-provider';
import { AuthenticationFacade } from '~/app/core/state/authentication/authentication.facade';
import { ConfigurationFacade } from '~/app/core/state/configuration/configuration.facade';

export const ssoGuard: CanActivateFn = () => {
    const configurationFacade = inject(ConfigurationFacade);

    if (configurationFacade.getAuthenticationSnapshot()?.type === 'OAUTH') {
        const authenticationFacade = inject(AuthenticationFacade);
        if (!authenticationFacade.getTokenSnapshot()) {
            const oauthProvider = inject(OAuthProvider);
            const document = inject(DOCUMENT);
            document.location.href = oauthProvider.getLoginUrl();
            return false;
        }
    }
    return true;
};
